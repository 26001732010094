// export const calculateIsoTime = (day, interval) => {
//     if (day || interval) {
//         const today = new Date(); // Get current date and time
//         const daysLater = new Date(today);
//         daysLater.setDate(today.getDate() + day); // Add 2 days

import { getNoteLabelByValue, selectPeriodOptions, unitCalenderIntervalOptions, unitIntervalOptions } from './constant';

//         if (interval === 'week') {
//             daysLater.setDate(today.getDate() + day * 7); // Add week
//         } else if (interval === 'month') {
//             daysLater.setDate(today.getDate() + day * 30); // Add week
//         } else if (interval === 'year') {
//             daysLater.setDate(today.getDate() + day * 360); // Add week
//         }

//         // Formatting the result
//         const fYear = daysLater.getFullYear();
//         const fMonth = String(daysLater.getMonth() + 1).padStart(2, '0');
//         const fDay = String(daysLater.getDate()).padStart(2, '0');

//         const formattedDate = `${fYear}-${fMonth}-${fDay}`;
//         return new Date(formattedDate).toISOString();
//     } else {
//         return '';
//     }
// };

export const isoTime = (count, interval) => {
    if (count || interval) {
        const date = new Date();
        if (interval === 'day') {
            date.setDate(date.getDate() + count);
            console.log('day', date.toISOString());
            return date.toISOString();
        } else if (interval === 'week') {
            date.setDate(date.getDate() + count * 7);
            console.log('week', date.toISOString());
            return date.toISOString();
        } else if (interval === 'month') {
            date.setMonth(date.getMonth() + count);
            console.log('month', date.toISOString());
            return date.toISOString();
        } else if (interval === 'year') {
            date.setFullYear(date.getFullYear() + count);
            console.log('month', date.toISOString());
            return date.toISOString();
        }
    } else {
        return '';
    }
};

export const isoTimeToday = () => {
    const today = new Date(); // Get current date and time

    const fYear = today.getFullYear();
    const fMonth = String(today.getMonth() + 1).padStart(2, '0');
    const fDay = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${fYear}-${fMonth}-${fDay}`;
    return new Date(formattedDate).toISOString();
};

export const isoCalendarTimeNow = (calendarDate) => {
    const today = new Date();
    const date = new Date(calendarDate);

    // YYYY-MM-DD of calendar
    const calendarYear = date.getFullYear().toString().padStart(4, '0');
    const calendarMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const calendarDay = date.getDate().toString().padStart(2, '0');

    // HH:MM:DD of today
    const hour = today.getHours().toString().padStart(2, '0');
    const minute = today.getMinutes().toString().padStart(2, '0');
    const second = today.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${calendarYear}-${calendarMonth}-${calendarDay} ${hour}:${minute}:${second}`;
    return new Date(formattedDate).toISOString();
};

export const convertToTitleCase = (str) => {
    return str
        .replace(/_/g, ' ')
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const calculateAge = (birthDate) => {
    if (birthDate) {
        const birthDay = new Date(birthDate);
        const today = new Date();
        const diff = today - birthDay;
        const ageDate = new Date(diff);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    } else {
        return '';
    }
};

export const replaceTextWithLineBreaks = (text) => {
    const replaceText = text.replace(/\./g, '');
    return replaceText;
};

export const convertEnToBnNumber = (number) => {
    let numStr = String(number);
    if (number) {
        let enToBnArr = {
            0: '০',
            1: '১',
            2: '২',
            3: '৩',
            4: '৪',
            5: '৫',
            6: '৬',
            7: '৭',
            8: '৮',
            9: '৯'
        };
        for (var x in enToBnArr) {
            numStr = numStr.replace(new RegExp(x, 'g'), enToBnArr[x]);
        }
        return numStr;
    }
    return '';
};

export const getBnPeriod = (period) => {
    switch (period.toLowerCase()) {
        case selectPeriodOptions[0].label.toLowerCase():
            return 'দিন';
        case selectPeriodOptions[1].label.toLowerCase():
            return 'সপ্তাহে';
        case selectPeriodOptions[2].label.toLowerCase():
            return 'দুই সপ্তাহে';
        case selectPeriodOptions[3].label.toLowerCase():
            return 'তিন সপ্তাহে';
        case selectPeriodOptions[4].label.toLowerCase():
            return 'মাসে';
        case selectPeriodOptions[5].label.toLowerCase():
            return 'দুই মাসে';
        case selectPeriodOptions[6].label.toLowerCase():
            return 'তিন মাসে';
        case selectPeriodOptions[7].label.toLowerCase():
            return 'ছয় মাসে';
        case selectPeriodOptions[8].label.toLowerCase():
            return 'বছরে';
        default:
            return 'দিন';
    }
};

export const getBnInterval = (interval) => {
    switch (interval) {
        case unitIntervalOptions[0].value.toLowerCase(): // hour
            return 'ঘন্টা';
        case unitIntervalOptions[1].value.toLowerCase(): // minute
            return 'মিনিট';

        case unitCalenderIntervalOptions[0].value.toLowerCase(): // day
            return 'দিন';
        case unitCalenderIntervalOptions[1].value.toLowerCase(): // week
            return 'সপ্তাহ';
        case unitCalenderIntervalOptions[2].value.toLowerCase(): // month
            return 'মাস';
        case unitCalenderIntervalOptions[3].value.toLowerCase(): // year
            return 'বছর';
        default:
            return '';
    }
};

export const labelDosageTiming = (data) => {
    const mealTimeQuantity = convertEnToBnNumber(data.mealTimeQuantity);
    const mealTimeUnit = getBnInterval(data.mealTimeUnit);
    const bnDosageTiming = getNoteLabelByValue(data.dosageTiming);
    const bnDosageTimingArr = bnDosageTiming.split(' ');
    const res = `${bnDosageTimingArr[0]} ${mealTimeQuantity} ${mealTimeUnit} ${bnDosageTimingArr[1]}`;

    return res;
};
